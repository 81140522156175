import { DATA_TEST_IDS } from '../../constants/';
import { MESSAGE_STRINGS } from '../../constants/en-us';
import {
  DigitalTwinTitleContainer,
  PlantTitleTypography,
} from '../../utils/styles';

const DigitalTwinPageTitle = (isMobileView) => {
  return (
    <DigitalTwinTitleContainer
      data-testid={DATA_TEST_IDS.DIGITAL_TWIN_TITLE}
      isMobileView={isMobileView}
    >
      <PlantTitleTypography>{MESSAGE_STRINGS.PAGE_TITLE}</PlantTitleTypography>
    </DigitalTwinTitleContainer>
  );
};

export default DigitalTwinPageTitle;
