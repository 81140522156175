import { config } from '@smf/ui-main-container-app';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import DigitalTwin from '../containers/DigitalTwin/index';

const MainApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path={`${config.BASE_APP_PATH}/digital_twin`}
          element={<DigitalTwin />}
        />
        <Route
          render={() => (
            <Route
              path="*"
              element={<Navigate to={config.BASE_APP_PATH} replace />}
            />
          )}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default MainApp;
