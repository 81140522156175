import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { config as globalConfig } from '@smf/ui-main-container-app';
import { Box, Skeleton } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { IoTTwinMakerClient } from '@aws-sdk/client-iottwinmaker';
import { initialize } from '@iot-app-kit/source-iottwinmaker';
import { SceneComposerInternal } from '@iot-app-kit/scene-composer';
import { useQuery } from '@tanstack/react-query';
import { updateRxjsState, useRxjsState } from '../../hooks/useRxjsState';
import {
  getDigitalTwinHierarchy,
  getDigitalTwinConfig,
} from '../../utils/apiHelper';
import {
  getEopNavData,
  getIsOverlayClicked,
  getHierarchyMap,
  getLatestDataMap,
} from '../../utils/helpers';
import {
  DATA_TEST_IDS,
  GENERIC_CONSTANTS,
  USER_POOL_ID_IDENTIFIER,
  URL_PATHS,
  queryNames,
  SCENE_MODE,
  LINK_DESTINATIONS,
} from '../../constants';
import { SceneContainer } from '../../utils/styles';

const hasValidCredentials = (
  factoryId,
  sessionToken,
  emailId,
  enableEditMode
) => {
  if (!factoryId || !sessionToken || !emailId || enableEditMode) {
    if (!factoryId) {
      console.info('Missing factoryId');
    }
    if (!sessionToken) {
      console.info('Missing sessionToken');
    }
    if (!emailId) {
      console.info('Missing emailId');
    }
    if (enableEditMode) {
      console.info('EditMode is enabled');
    }
    return null;
  }
};

const DigiTwin = (props) => {
  const {
    enableEditMode = false,
    handleSceneChange = () => {},
    setIsSceneLoading = () => {},
    hierarchyData = {},
  } = props;
  const [digitalTwinConfig, setDigitalTwinConfig] = useState({
    workspaceId: '',
    sceneId: '',
  });
  const [currentScene, setCurrentScene] = useState(null);
  const [linkDestination, setLinkDestination] = useState('');
  const [webSocketData, setWebSocketData] = useState([]);
  const [sceneViewerData, setSceneViewerData] = useState([]);
  const [digitalTwinHierarchyMap, setDigitalTwinHierarchyMap] = useState({});
  const { rxjsState } = useRxjsState();
  const { factoryId } = rxjsState;
  const emailId = rxjsState?.userData?.email;
  const sessionToken = rxjsState?.sessionToken;
  const eopNavDataRef = useRef(null);
  const webSocketRef = useRef(null);

  /**
   * Fetching Digital Twin Configurations
   */
  const { isLoading: isTwinmakerConfigApiLoading } = useQuery({
    queryKey: [queryNames.GET_DIGITAL_TWIN_CONFIG],
    queryFn: async () => {
      const data = await getDigitalTwinConfig(factoryId);
      return data;
    },
    onSuccess: (res) => {
      setDigitalTwinConfig(res);
    },
    enabled: !!factoryId,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const { data: digitalTwinHierarchy } = useQuery({
    queryKey: [queryNames.GET_TWINMAKER_HIERARCHY],
    queryFn: async () => {
      const data = await getDigitalTwinHierarchy(factoryId);
      setWebSocketData([]);
      setDigitalTwinHierarchyMap([]);
      return data;
    },
    enabled: !!factoryId && !enableEditMode,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  async function getScene() {
    const userPoolId = globalConfig.FORMATTED_USER_POOL_ID.replace(
      USER_POOL_ID_IDENTIFIER,
      globalConfig.USER_POOL_ID
    );
    const cognitoidentity = new CognitoIdentityClient({
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({
          region: globalConfig.AWS_REGION,
        }),
        identityPoolId: globalConfig.IDENTITY_POOL_ID,
        logins: {
          [userPoolId]: rxjsState?.idToken,
        },
      }),
    });

    const credentials = await cognitoidentity.config.credentials();

    const iotTwinMakerClient = new IoTTwinMakerClient({
      region: globalConfig.AWS_REGION,
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });

    const currentSceneLoader = initialize(digitalTwinConfig.workspaceId, {
      iotTwinMakerClient,
      awsRegion: globalConfig.AWS_REGION,
      awsCredentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });

    setCurrentScene(currentSceneLoader);
  }

  const navigateToEop = (eopNavData, scrollToOC = false) => {
    updateRxjsState({
      eop: {
        ...rxjsState.eop,
        navList: eopNavData.navList,
        eopName: `${eopNavData.entityNumber} - ${eopNavData.entityName}`,
        entityId: `${eopNavData.entityId}`,
        scrollToOC: scrollToOC,
        entityKey: `${eopNavData.entityKey}`,
      },
    });
    navigateToUrl(
      `${globalConfig.BASE_APP_PATH}${URL_PATHS.EQUIPMENT_OVERVIEW}`
    );
  };

  const handleWidgetClick = (event) => {
    const isTagClicked = (event) => {
      return (
        event?.additionalComponentData?.length &&
        event.additionalComponentData[0].hasOwnProperty('navLink')
      );
    };
    if (isTagClicked(event)) {
      if (event?.additionalComponentData[0]?.navLink) {
        setLinkDestination(
          event?.additionalComponentData[0]?.navLink?.destination
        );
      }
    }
    const isOverlayClicked = getIsOverlayClicked(event);
    if (isOverlayClicked) {
      switch (linkDestination) {
        case LINK_DESTINATIONS.PLANT_OVERVIEW:
          navigateToUrl(
            `${globalConfig.BASE_APP_PATH}${URL_PATHS.PLANT_OVERVIEW}`
          );
          break;
        case LINK_DESTINATIONS.EQUIPMENT_OVERVIEW:
          eopNavDataRef.current = getEopNavData(event, hierarchyData);
          if (eopNavDataRef.current) navigateToEop(eopNavDataRef.current);
          break;
        case LINK_DESTINATIONS.COMMAND_CENTER:
          navigateToUrl(
            `${globalConfig.BASE_APP_PATH}${URL_PATHS.COMMAND_CENTER}`
          );
          break;
        case LINK_DESTINATIONS.PO_SEQUENCING:
          navigateToUrl(
            `${globalConfig.BASE_APP_PATH}${URL_PATHS.PO_SEQUENCING}`
          );
          break;
        case LINK_DESTINATIONS.SMART_SPC:
          navigateToUrl(`${globalConfig.BASE_APP_PATH}${URL_PATHS.SMART_SPC}`);
          break;
        case LINK_DESTINATIONS.ALL_PLANTS:
          navigateToUrl(`${globalConfig.BASE_APP_PATH}`);
          break;
      }
    }
  };

  useEffect(() => {
    const constructWebSocketUrl = () => {
      try {
        if (
          hasValidCredentials(factoryId, sessionToken, emailId, enableEditMode)
        ) {
          return null;
        }
        return `${URL_PATHS.WSS_DIGITAL_TWIN}?plantId=${factoryId}&emailId=${emailId}&token=${sessionToken}&source=digitalTwin`;
      } catch (error) {
        console.error(
          'An error occurred in constructing websocket url:',
          error
        );
        return null;
      }
    };
    const url = constructWebSocketUrl();
    if (!url) return;
    initializeWebSocket(url);
    return () => cleanupWebSocket();
  }, [factoryId, sessionToken, emailId, enableEditMode]);

  const initializeWebSocket = (url) => {
    webSocketRef.current = new WebSocket(url);
    webSocketRef.current.onopen = (event) =>
      // eslint-disable-next-line no-console
      console.debug('Digital Twin ws connected at ', event.timeStamp);
    webSocketRef.current.onclose = (event) =>
      // eslint-disable-next-line no-console
      console.debug('Digital Twin ws disconnected due to ', event.reason);
    webSocketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setWebSocketData(message);
    };
    webSocketRef.current.onError = (event) =>
      console.error('Digital Twin ws error due to ', event.reason);
  };
  const cleanupWebSocket = () => {
    // eslint-disable-next-line no-console
    console.debug('Cleaning up Digital Twin ws connection');
    if (webSocketRef.current) {
      webSocketRef.current.close();
    }
  };

  useEffect(() => {
    if (digitalTwinHierarchy?.length) {
      setSceneViewerData(digitalTwinHierarchy);
      const hierarchyMap = getHierarchyMap(digitalTwinHierarchy);
      setDigitalTwinHierarchyMap(hierarchyMap);
    }
  }, [digitalTwinHierarchy]);

  useEffect(() => {
    if (
      webSocketData?.length &&
      digitalTwinHierarchyMap &&
      Object.keys(digitalTwinHierarchyMap)?.length
    ) {
      const latestDataMap = getLatestDataMap(
        webSocketData,
        digitalTwinHierarchyMap
      );
      setDigitalTwinHierarchyMap(latestDataMap);
      setSceneViewerData(Object.values(digitalTwinHierarchyMap).flat());
    }
  }, [digitalTwinHierarchyMap, webSocketData]);

  useEffect(() => {
    if (
      rxjsState.idToken &&
      digitalTwinConfig.sceneId &&
      digitalTwinConfig.workspaceId
    ) {
      getScene();
    }
  }, [
    rxjsState.idToken,
    digitalTwinConfig.sceneId,
    digitalTwinConfig.workspaceId,
  ]);

  useEffect(() => {
    setIsSceneLoading(isTwinmakerConfigApiLoading);
  }, [isTwinmakerConfigApiLoading]);

  return (
    <Box sx={{ height: '100%' }}>
      <SceneContainer data-testid={DATA_TEST_IDS.SCENE_CONTAINER}>
        {currentScene ? (
          <SceneComposerInternal
            dataStreams={sceneViewerData}
            viewport={{ duration: GENERIC_CONSTANTS.SCENE_DURATION }}
            sceneLoader={currentScene?.s3SceneLoader(digitalTwinConfig.sceneId)}
            sceneComposerId={digitalTwinConfig.sceneId}
            activeCamera={GENERIC_CONSTANTS.ACTIVE_CAMERA}
            onWidgetClick={handleWidgetClick}
            config={{
              mode: enableEditMode ? SCENE_MODE.EDITING : SCENE_MODE.VIEWING,
            }}
            {...(enableEditMode && {
              onSceneUpdated: (snap) => {
                handleSceneChange(snap);
              },
            })}
          />
        ) : (
          !enableEditMode && (
            <Skeleton variant="rectangular" height="100%" width="100%" />
          )
        )}
      </SceneContainer>
    </Box>
  );
};

DigiTwin.propTypes = {
  enableEditMode: PropTypes.bool,
  handleSceneChange: PropTypes.func,
  setIsSceneLoading: PropTypes.func,
  hierarchyData: PropTypes.object,
};

export default DigiTwin;
