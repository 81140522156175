import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import { DATA_TEST_IDS } from '../../constants/';
import {
  DigitalTwinDetailsContainer,
  DigitalTwinDetailsTypography,
  DividerContainer,
  DividerLine,
  FlexBox,
  PlantTimeDetailsBox,
} from '../../utils/styles';
import { getTimeFromTimezone } from '../../utils/helpers';
import { MESSAGE_STRINGS } from '../../constants/en-us';

const DigitalTwinDetails = (props) => {
  const { plantTimeZone, plantLocation } = props;
  const [plantTime, setPlantTime] = useState(
    plantLocation ? getTimeFromTimezone(plantLocation) : ''
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setPlantTime(plantLocation ? getTimeFromTimezone(plantLocation) : '');
    }, 1000);

    return () => clearInterval(interval);
  }, [plantLocation]);

  return (
    <DigitalTwinDetailsContainer>
      <FlexBox data-testid={DATA_TEST_IDS.PLANT_TIME}>
        <Box>
          <DigitalTwinDetailsTypography>
            {`${MESSAGE_STRINGS.PLANT_TIME}: `}
          </DigitalTwinDetailsTypography>
        </Box>
        <PlantTimeDetailsBox>
          {!plantTime || !plantTimeZone ? (
            <Skeleton height="1.5rem" width="4.75rem" />
          ) : (
            <DigitalTwinDetailsTypography>
              {`${plantTime} ${plantTimeZone}`}
            </DigitalTwinDetailsTypography>
          )}
        </PlantTimeDetailsBox>
      </FlexBox>
      <DividerContainer>
        <DividerLine />
      </DividerContainer>

      <Box data-testid={DATA_TEST_IDS.LIVE_DATA}>
        <DigitalTwinDetailsTypography>
          {MESSAGE_STRINGS.LIVE_DATA}
        </DigitalTwinDetailsTypography>
      </Box>
    </DigitalTwinDetailsContainer>
  );
};

DigitalTwinDetails.propTypes = {
  plantTimeZone: PropTypes.string,
  plantLocation: PropTypes.string,
};

export default DigitalTwinDetails;
