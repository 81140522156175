import { StrictMode } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material';
import {
  SplitClient,
  SplitFactory,
  SplitTreatments,
} from '@splitsoftware/splitio-react';
import { lightTheme, darkTheme } from '@smf/ui-styleguide';
import {
  ErrorBoundary,
  globalstate$,
  splitRootFactory,
  FEATURE_FLAGS,
} from '@smf/ui-main-container-app';
import MainApp from './Routes/index';
import { useRxjsState } from './hooks/useRxjsState';
import { MESSAGE_STRINGS } from './constants/en-us';

export default function Root() {
  const { rxjsState } = useRxjsState();
  const queryClient = new QueryClient();
  const currentTheme = rxjsState.isDark ? darkTheme : lightTheme;

  const renderApp = () => {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={currentTheme}>
          <MainApp />
        </ThemeProvider>
      </QueryClientProvider>
    );
  };

  return (
    <StrictMode>
      <ErrorBoundary>
        <SplitFactory factory={splitRootFactory}>
          <SplitClient
            splitKey={globalstate$._value?.useData?.email?.toLowerCase()}
          >
            <SplitTreatments names={[FEATURE_FLAGS.DT]}>
              {({ isReady }) => {
                return isReady ? (
                  renderApp()
                ) : (
                  <>{MESSAGE_STRINGS['Loading.Indicator']}</>
                );
              }}
            </SplitTreatments>
          </SplitClient>
        </SplitFactory>
      </ErrorBoundary>
    </StrictMode>
  );
}
