import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import DigiTwin from './DigitalTwin/scene';
import { lightTheme, darkTheme } from '@smf/ui-styleguide';
import { ThemeProvider } from '@mui/material';
import { useRxjsState } from '../hooks/useRxjsState';

const DigitalTwinScene = (props) => {
  const { rxjsState } = useRxjsState();
  const queryClient = new QueryClient();
  const currentTheme = rxjsState.isDark ? darkTheme : lightTheme;

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={currentTheme}>
        <DigiTwin {...props} />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default DigitalTwinScene;
