import { Box, Breadcrumbs } from '@mui/material';
import { config } from '@smf/ui-main-container-app';
import { useNavigate } from 'react-router-dom';
import { useRxjsState } from '../../hooks/useRxjsState';
import { DATA_TEST_IDS, URL_PATHS } from '../../constants';
import {
  AllPlantsAndPlantNameTypography,
  DigitalTwinTypography,
} from '../../utils/styles';
import { MESSAGE_STRINGS } from '../../constants/en-us';

const NavigationBreadcrumb = () => {
  const { rxjsState } = useRxjsState();
  const navigate = useNavigate();

  const plantName = rxjsState.plantName;

  const handleAllPlantsClick = () => {
    navigate(config.BASE_APP_PATH);
  };

  const handlePlantNameClick = () => {
    navigate(`${config.BASE_APP_PATH}${URL_PATHS.PLANT_OVERVIEW}`);
  };

  return (
    <Box sx={{ display: 'flex', marginBottom: '0.313rem' }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Box sx={{ marginRight: '0.125rem' }}>
          <AllPlantsAndPlantNameTypography
            data-testid={DATA_TEST_IDS.ALL_PLANTS}
            onClick={handleAllPlantsClick}
          >
            {MESSAGE_STRINGS.ALL_PLANTS}
          </AllPlantsAndPlantNameTypography>
        </Box>
        <Box data-testid={DATA_TEST_IDS.PLANT_NAME_BOX}>
          <AllPlantsAndPlantNameTypography
            data-testid={DATA_TEST_IDS.PLANT_NAME_TEXT}
            onClick={handlePlantNameClick}
          >
            {plantName}
          </AllPlantsAndPlantNameTypography>
        </Box>
        <Box data-testid={DATA_TEST_IDS.DIGITAL_TWIN_BOX}>
          <DigitalTwinTypography>
            {MESSAGE_STRINGS.DIGITAL_TWIN}
          </DigitalTwinTypography>
        </Box>
      </Breadcrumbs>
    </Box>
  );
};

export default NavigationBreadcrumb;
